/* eslint-disable import/prefer-default-export */
import { IRootState } from '@/modules/common/common.interface';
import { EErrorStatus } from '@/modules/error/error.enum';
import Api from '@/services/api/ApiFactory';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import {
  IConfigurableIntegrations,
  ICreateIntegrationParams,
  TIntegration
} from '@/views/private/integration/types/interface';
import { TIntegrationType } from '@/views/private/integration/types/type';
import {
  CreateWebhookIntegrationParams,
  UpdateWebhookIntegrationParams
} from '@connect-saas/modules/integration';
import { IIntegrationWebhookApi } from '@connect-saas/types';
import { AxiosError } from 'axios';
import { ActionContext, ActionTree } from 'vuex';
import { IIntegrationState } from './types';

const ApiFactory = new Api();

export const actions: ActionTree<IIntegrationState, IRootState> = {
  GET_INTEGRATION_LIST({
    commit,
    dispatch
  }: ActionContext<IIntegrationState, IRootState>): Promise<IConfigurableIntegrations> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration()
        .getAllIntegration<IConfigurableIntegrations>()
        .then((response: IConfigurableIntegrations) => {
          commit('UPDATE_INTEGRATION_LIST', response);
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            if (errorResponse?.response?.status !== EErrorStatus.NotFound) {
              dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            }
            reject(errorResponse);
          }
        });
    });
  },
  GET_INTEGRATION_BY_TYPE(
    { commit, dispatch }: ActionContext<IIntegrationState, IRootState>,
    type: TIntegrationType
  ): Promise<TIntegration> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration(type)
        .getIntegrationData<TIntegration>()
        .then((response: TIntegration) => {
          commit('UPDATE_SELECTED_INTEGRATION', response);
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            if (errorResponse?.response?.status !== EErrorStatus.NotFound) {
              dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            }
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_INTEGRATION(
    { commit, dispatch }: ActionContext<IIntegrationState, IRootState>,
    payload: ICreateIntegrationParams
  ): Promise<ResourceResponse<TIntegration>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration(payload.type)
        .createIntegration(payload.data)
        .then((response: ResourceResponse<TIntegration>) => {
          commit('UPDATE_SELECTED_INTEGRATION', response.resource);
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DELETE_INTEGRATION(
    { dispatch }: ActionContext<IIntegrationState, IRootState>,
    type: TIntegrationType
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration(type)
        .deleteIntegration()
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_ALL_WEBHOOK({
    commit,
    dispatch
  }: ActionContext<IIntegrationState, IRootState>): Promise<IIntegrationWebhookApi[]> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration()
        .getAllWebhook()
        .then((response: { data: IIntegrationWebhookApi[] }) => {
          commit('UPDATE_WEBHOOK_LIST', response.data);
          resolve(response.data);
        })
        .catch((errorResponse: AxiosError) => {
          if (errorResponse instanceof Error) {
            if (errorResponse?.response?.status !== EErrorStatus.NotFound) {
              dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            }
            reject(errorResponse);
          }
        });
    });
  },
  GET_WEBHOOK_BY_ID(
    { commit, dispatch }: ActionContext<IIntegrationState, IRootState>,
    id: string
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration()
        .getWebhookById(id)
        .then((response: ResourceResponse<IIntegrationWebhookApi | null>) => {
          commit('UPDATE_SELECTED_WEBHOOK', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_WEBHOOK(
    { commit, dispatch }: ActionContext<IIntegrationState, IRootState>,
    payload: CreateWebhookIntegrationParams
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration()
        .createWebhook(payload)
        .then((response: ResourceResponse<IIntegrationWebhookApi>) => {
          commit('UPDATE_SELECTED_WEBHOOK', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_WEBHOOK_BY_ID(
    { commit, dispatch }: ActionContext<IIntegrationState, IRootState>,
    payload: { id: string; body: UpdateWebhookIntegrationParams }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration()
        .updateWebhookById(payload.id, payload.body)
        .then((response: ResourceResponse<IIntegrationWebhookApi>) => {
          commit('UPDATE_SELECTED_WEBHOOK', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_WEBHOOK(
    { dispatch }: ActionContext<IIntegrationState, IRootState>,
    id: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getIntegration()
        .deleteWebhook(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};
