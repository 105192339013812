import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { IIntegrationState } from './types';

const state: IIntegrationState = {
  integration_list: null,
  selected_integration: null,
  webhook_list: [],
  selected_webhook: null
};

// eslint-disable-next-line import/prefer-default-export
export const integration: Module<IIntegrationState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
