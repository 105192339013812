import ClientInterface from '@/services/api/common/ClientInterface';
import { TIntegrationType } from '@/views/private/integration/types/type';
import {
  CreateWebhookIntegrationParams,
  UpdateWebhookIntegrationParams
} from '@connect-saas/modules/integration';
import { IIntegrationWebhookApi, TWebhookEvent } from '@connect-saas/types';
import ResourceResponse from './common/ResourceResponse';

export default class Integration {
  private readonly client: ClientInterface;

  private readonly type: string;

  private readonly resource: string;

  constructor(client: ClientInterface, type: TIntegrationType) {
    this.client = client;
    let newType: string = type;
    if (type === 'meta_pixel') {
      newType = 'meta-pixel';
    }
    this.type = newType;
    this.resource = 'integration';
  }

  getAllIntegration<T>(): Promise<T> {
    return this.client.get(this.resource);
  }

  getIntegrationData<T>(): Promise<T> {
    return this.client.get(`${this.resource}/${this.type}`);
  }

  createIntegration<T, G>(body: T): Promise<ResourceResponse<G>> {
    return this.client.create<T, G>(`${this.resource}/${this.type}`, body);
  }

  deleteIntegration(): Promise<void> {
    return this.client.delete(`${this.resource}/${this.type}`);
  }

  getAllWebhook(): Promise<{ data: IIntegrationWebhookApi[] }> {
    return this.client.get(`${this.resource}/webhook`);
  }

  getWebhookById(id: string): Promise<ResourceResponse<IIntegrationWebhookApi | null>> {
    return this.client.getById(`${this.resource}/webhook`, id);
  }

  createWebhook(
    body: CreateWebhookIntegrationParams
  ): Promise<ResourceResponse<IIntegrationWebhookApi>> {
    return this.client.create(`${this.resource}/webhook`, body);
  }

  updateWebhookById(
    id: string,
    body: UpdateWebhookIntegrationParams
  ): Promise<ResourceResponse<IIntegrationWebhookApi>> {
    return this.client.patch(`${this.resource}/webhook`, body, id);
  }

  deleteWebhook(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/webhook`, id);
  }

  testWebhook(id: string, event: TWebhookEvent): Promise<ResourceResponse<{ success: boolean }>> {
    return this.client.post(`${this.resource}/webhook/${id}/test`, { event });
  }
}
