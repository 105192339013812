export enum ECard {
  GOOGLE_ADWORDS = 'Google Adwords',
  GOOGLE_ANALYTICS = 'Google Analytics',
  GOOGLE_TAG_MANAGER = 'Google Tag Manager',
  META_PIXEL = 'Meta Pixel',
  WEBHOOK = 'Webhook'
}
export enum ECardLink {
  GOOGLE_ADWORDS = 'google-adwords',
  GOOGLE_ANALYTICS = 'google-analytics',
  GOOGLE_TAG_MANAGER = 'google-tag-manager',
  META_PIXEL = 'meta-pixel',
  WEBHOOK = 'webhook'
}
