import {
  IConfigurableIntegrations,
  TIntegration
} from '@/views/private/integration/types/interface';
import { IIntegrationWebhookApi } from '@connect-saas/types';
import { MutationTree } from 'vuex';
import { IIntegrationState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IIntegrationState> = {
  UPDATE_INTEGRATION_LIST(state: IIntegrationState, integration_list: IConfigurableIntegrations) {
    state.integration_list = integration_list;
  },
  UPDATE_SELECTED_INTEGRATION(state: IIntegrationState, selected_integration: TIntegration) {
    state.selected_integration = selected_integration;
  },
  UPDATE_WEBHOOK_LIST(state: IIntegrationState, webhook_list: IIntegrationWebhookApi[]) {
    state.webhook_list = webhook_list;
  },
  UPDATE_SELECTED_WEBHOOK(state: IIntegrationState, selected_webhook: IIntegrationWebhookApi) {
    state.selected_webhook = selected_webhook;
  }
};
